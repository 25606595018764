/* Hero section layout */
.hero-section {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .hero-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10vh; /* Adjust this value to move title/subtitle higher or lower */
  }
  
  .title-container {
    width: 80%; /* This creates 10% padding on either side */
    text-align: center;
  }
  
  /* Title and subtitle styles */
  .hero-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  /* Button container */
  .button-container {
    position: absolute;
    top: 24vh; /* Adjust this value to move buttons up or down */
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Button styles */
  .join-button, .donate-button {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1.2rem 2rem;
    border-radius: 9999px;
    transition: background-color 0.3s;
    white-space: nowrap;
    
  }
  
  .join-button {
    background-color: #EFBC40;
    color: black;
  }
  
  .join-button:hover {
    background-color: #D5A738;
  }
  
  .donate-button {
    background-color: #EA328B;
    color: white;
  }
  
  .donate-button:hover {
    background-color: #D12D7B;
  }
  
  /* Portrait mode styles */
  @media (orientation: portrait) {
    .button-container {
      flex-direction: column;
      align-items: center;
      top: 60vh; /* Adjust for landscape */
    }
  
    .join-button {
      margin-bottom: 2rem;
    }
  
    .donate-button {
      border-radius: 9999px; /* Fully rounded in portrait mode */
    }
  
    /* Increased font sizes for portrait mode */
    /* .hero-title {
      font-size: 4rem;
    }
  
    .hero-subtitle {
      font-size: 2rem;
    } */

    .hero-content{
      padding-top: 2vh; /* Adjust this value to move title/subtitle higher or lower */

    }
  }
  
  /* Landscape mode styles */
  @media (orientation: landscape) {
    .button-container {
      flex-direction: row;
      padding-right: 0; /* Remove right padding */
      top: 70vh; /* Adjust for landscape */
    }
  
    .donate-button {
      position: absolute;
      right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .hero-content{
      padding-top: 8vh; /* Adjust this value to move title/subtitle higher or lower */

    }
  }
  

  /*----------*/

  :root {
    --bloom-yellow: #EFBC40;
    --bloom-pink: #EA328B;
  }
  
  /* Why Bloom Section */
  .why-bloom-section {
    background-color: #f8f8f8;
  }
  
  .gradient-line {
    width: 40%; /* Reduced from 80% to about half its size */
    height: 4px;
    background: linear-gradient(to right, var(--bloom-yellow), var(--bloom-pink));
  }
  
  .learn-more-btn {
    background: linear-gradient(to right, var(--bloom-yellow), var(--bloom-pink));
    color: white;
    font-weight: bold;
    padding: 12px 24px;
    border-radius: 9999px;
    transition: opacity 0.3s;
  }
  
  .learn-more-btn:hover {
    opacity: 0.9;
  }
  
  .action-btn {
    /* background-color: var(--bloom-yellow); */
    color: black;
    font-weight: bold;
    padding: 12px 24px;
    border-radius: 9999px;
    transition: background-color 0.3s;
  }
  
  .action-btn:hover {
    background-color: #D5A738;
  }
  
  /* Make the "Make an impact" button pink */
  .action-btn:last-child {
    /* background-color: var(--bloom-pink); */
    color: white;
  }
  
  .action-btn:last-child:hover {
    background-color: #D12D7B;
  }
  
  /* Updated styles for the gradient border and consistent button alignment */
  
  .card-with-gradient-border {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden;
    padding: 20px;
    margin-bottom: 20px;
    min-height: 250px; /* Increased to accommodate more spacing */
    text-align: center; /* Center align all text */
  }
  
  .card-with-gradient-border::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, var(--bloom-yellow), var(--bloom-pink));
    z-index: 0;
    padding: 2px;
    border-radius: inherit;
  }
  
  .card-with-gradient-border::after {
    content: '';
    position: absolute;
    inset: 2px;
    background: white;
    border-radius: calc(0.5rem - 2px);
    z-index: 1;
  }
  
  .card-with-gradient-border > * {
    position: relative;
    z-index: 2;
  }
  
  .card-with-gradient-border h3 {
    margin-bottom: 15px;
  }
  
  .card-with-gradient-border p {
    margin-bottom: 25px; /* Increased spacing above button */
  }
  
  .card-with-gradient-border .action-btn {
    margin-top: auto;
    margin-bottom: 15px; /* Added spacing below button */
    align-self: center;
    padding: 12px 24px; /* Increased button padding */
  }
  
  /* Ensure consistent layout in portrait mode */
  @media (orientation: portrait) {
    .card-with-gradient-border {
      text-align: center;
    }
  }


/*------*/


/* Menu button styles */
.menu-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3));
  transition: filter 0.3s ease;
}

.menu-button:hover {
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.5));
}

/* Drawer menu styles */
.menu-drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  background-color: white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.menu-drawer.open {
  transform: translateX(0);
}

.menu-drawer nav ul {
  list-style-type: none;
  padding: 0;
}

.menu-drawer nav ul li {
  margin-bottom: 1rem;
}

.menu-drawer nav ul li a {
  font-size: 1.5rem;
  color: black;
  text-decoration: none;
  display: block;
  padding: 0.5rem 0;
}

/* Responsive styles for the menu drawer */
@media (max-width: 768px) {
  .menu-drawer {
    width: 80%;
    max-width: 400px;
  }
}

@media (max-width: 480px) {
  .menu-drawer {
    width: 100%;
    max-width: none;
  }

  .menu-drawer nav ul li a {
    font-size: 1.8rem;
    padding: 0.75rem 0;
  }
}
